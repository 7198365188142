import React, { useState } from "react"
import styled from "styled-components"
import { ContentWrapper, Flex } from "../../layout/reusables"
import { colors } from "../../styles/colors"
import { BodyCopy, DigestiveText, HeadlineText } from "../../styles/typography"
import PhealLoader from "../pheal-loader"
import ReadWatchResult from "./read-watch-result"
import { motion } from 'framer-motion'

const Container = styled(motion.section)`
    background: ${colors.yellow};
    color: black;
    text-align: center;
    padding: 7vh 20px;
`
const Content = styled(Flex)`
    margin-bottom: 30px;
`
const Subtitle = styled.p`
    margin-bottom: 30px;
`
const Prompt = styled(HeadlineText)`
    font-size: clamp(35px, 6.5vw, 70px);
    margin-bottom: 30px;
`
const Actions = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 15px;

    @media (max-width: 550px) {
        grid-template-columns: 100%;
        width: 100%;
    }
`
const Button = styled.button`
    border: 1px solid black;
    color: black;
    text-align: center;
    text-transform: uppercase;
    padding: 15px 35px;
    font-size: 14px;
    outline: none;
    cursor: pointer;
    transition: 0.2s ease-out;
    background: none;
    min-width: 150px;
    :hover {
        background: black;
        color: white;
    }
    :focus {
        outline: none;
    }
    :disabled {
        /* background: #707070; */
    }
    @media (max-width: 550px) {
        font-size: 12px;
        padding: 12px 25px;
    }
`

const ReadWatchSection = ({ collection, name }) => {
    const [resource, setResource] = useState(null)
    const [loading, setLoading] = useState(false)
    const [emptyResources, setEmptyResources] = useState(false)

    const getRandomResource = type => {
        setLoading(true)
        setResource(null)
        setEmptyResources(false)
        const filteredResources = collection.filter(el => el.type === type)
        const randomResource =
            filteredResources[
                Math.floor(Math.random() * filteredResources.length)
            ]
        
        
        setTimeout(() => {
            setLoading(false)
            setResource(randomResource)
            if (!randomResource) {
                setEmptyResources(true)
            }
        }, 2500)
    }
    return (
        <Container layout>
            <ContentWrapper layout>
                <Content direction="column" align="center">
                    <Subtitle>{name}'s Read | Watch Collection</Subtitle>
                    <Prompt noUppercase>How do you pheal to learn?</Prompt>
                    <Actions>
                        <Button onClick={() => getRandomResource("Watch")}>Watch</Button>
                        <Button onClick={() => getRandomResource("Read")}>Read</Button>
                    </Actions>
                </Content>
                {loading && <PhealLoader />}
                {resource && <ReadWatchResult result={resource} />}
                {emptyResources && <BodyCopy>{name} hasn't added any of this type of resource yet.</BodyCopy>}
            </ContentWrapper>
        </Container>
    )
}

export default ReadWatchSection