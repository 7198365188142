import React, { useMemo } from "react"
import styled from "styled-components"
import { useCollection } from "react-firebase-hooks/firestore"
import Loader from "react-loader-spinner"
import { Flex } from "../../layout/reusables"
import { originalContentRef } from "../../state/FirebaseProvider"
import OriginalContentGroup from "./original-content-group"
import ContentTypesBanner from "./content-types-banner"
import EmptyOriginalContent from "../empty-states/empty-original-content"

const ViewOriginalContent = ({ userId, name, logo }) => {

    //TODO: Call for different content types individually, so we can limit to the first 3, instead of faking it.

    const query = originalContentRef
        .where("collaboratorId", "==", userId)
        .where("published", '==', true)
        .orderBy("createdAt", "desc")
    const [originalContent, loading, error] = useCollection(query)
    console.log({error})

    const originalContentData = useMemo(() => {
        if (originalContent) {
            return originalContent.docs.map(item => ({
                id: item.id,
                ...item.data(),
            }))
        }
        return []
    }, [originalContent])

    const originalContentTypes = useMemo(() => {
        if (originalContentData.length) {
            const allTypes = originalContentData.map(el => el.type)
            return [...new Set(allTypes)]
        }
        return []
    }, [originalContentData])

    return (
        <Container>
            <Flex
                justify="center"
                align="center"
                style={{ marginBottom: 20 }}
            >
                {loading && (
                    <Loader type="Oval" height={30} width={30} color="black" />
                )}
            </Flex>
            {/* {originalContentTypes.length > 0 &&
                <ContentTypesBanner contentTypes={originalContentTypes} />
            } */}
            {!loading && originalContentData.length === 0 &&
                <EmptyOriginalContent name={name} />
            }
            {!loading && originalContentData.length > 0 &&
                <OriginalContentGroup name={name} logo={logo} originalContentData={originalContentData} />
            
            }
        </Container>
    )
}

export default ViewOriginalContent

const Container = styled.div``
