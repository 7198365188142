import React from 'react'
import { useCollection } from 'react-firebase-hooks/firestore';
import CollaboratorHeader from '../components/channel/header';
import ReadWatchSection from '../components/channel/read-watch';
import ViewOriginalContent from '../components/channel/view-original-content';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { ContentWrapper, Wrapper } from '../layout/reusables';
import firebase from "gatsby-plugin-firebase"

export default function ChannelPage({ data }) {
    const { collaboratorData } = data
    const { id, logo, name, description } = collaboratorData

    const query = firebase
        .firestore()
        .collection("read-watch")
        .where("collaboratorId", "==", id)
    const [readWatches] = useCollection(query)

    return (
        <Layout>
            <SEO title={name} description={`${description} | See their exclusive channel now on PHEAL`} />
            <Wrapper>
                <CollaboratorHeader
                    name={name}
                    bio={description}
                    logo={logo}
                />
				
                {readWatches && 
					<ReadWatchSection name={name} collection={readWatches.docs.map((readWatch) => ({id: readWatch.id, ...readWatch.data()}))} />
                }
                <ContentWrapper style={{padding: 20}}>
                    <ViewOriginalContent userId={id} name={name} logo={logo} />
                </ContentWrapper>
            </Wrapper>
        </Layout>
    )
}

export const channelQuery = graphql`
  query($id: String!) {
    collaboratorData: collaborators(id: { eq: $id }) {
      id
      name
      logo
      description
    }
  }
`