import { motion } from "framer-motion"
import React from "react"
import styled from "styled-components"
import { fadeDuration } from "../../../project-constants"
import { TextWrapper } from "../../layout/reusables"
import { MainButton } from "../../styles/buttons"
import { BodyCopy } from "../../styles/typography"

const ReadWatchResult = ({ result }) => {
    return (
        <Container
            layout
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <TextWrapper>
                <BodyCopy fontSize={24}>Your Educational Resource</BodyCopy>
                <ResultContainer>
                    <ResultTitle>{result.title}</ResultTitle>
                    <ResultMeta>{result.description}</ResultMeta>
                    <a target="_blank" href={result.url} rel="noreferrer">
                        <MainButton
                            style={{ color: "black", marginBottom: 15 }}
                        >
                            {result.type === "Watch" ? "Watch" : "Read"} now
                        </MainButton>
                    </a>
                </ResultContainer>
            </TextWrapper>
        </Container>
    )
}

export default ReadWatchResult

const Container = styled(motion.div)`
    display: flex;
    flex-direction: column;
    align-items: center;
`
const ResultContainer = styled.div`
    margin-bottom: 10px;
`
const ResultTitle = styled.h3`
    font-weight: 500;
    font-size: 36px;
    line-height: 1.3;
    font-family: "Haas";
    max-width: 650px;
    margin-bottom: 9px;
    color: ${props => (props.color === "dark" ? "white" : "black")};
    transition: ${fadeDuration}s ease;
    @media (max-width: 550px) {
        font-size: 36px;
    }
`
const ResultMeta = styled.p`
    opacity: 0.5;
    color: ${props => (props.color === "dark" ? "white" : "black")};
    margin-bottom: 20px;
    transition: ${fadeDuration}s ease;
    line-height: 150%;
`