import React from "react"
import styled from "styled-components"
import { Flex, TextWrapper } from "../../layout/reusables"

const Container = styled(Flex)`
    min-height: 40vh;
    background: black;
`
const Logo = styled.img`
    width: 200px;
    height: 150px;
    margin-bottom: 20px;
    background: none;
    object-fit: contain;
`
const Content = styled(Flex)`
    padding: 20px;
    padding-top: 100px;
    padding-bottom: 30px;
`
const Name = styled.p`
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 5px;
    color: white;
    text-align: center;
    opacity: 0.7;
`
const Bio = styled.p`
    color: white;
    text-align: center;
    line-height: 1.4;
    font-size: 16px;
`
// https://logos-download.com/wp-content/uploads/2016/03/Nike_logo_just_do_it.png"
const CollaboratorHeader = ({ name, bio, logo }) => {
    return (
        <Container align="center" justify="center">
            <Content align="center" justify="center" direction="column">
                <Logo
                    src={
                        logo
                            ? logo
                            : require("../../images/logo-placeholder.png")
                    }
                />
                <TextWrapper>
                    <Name>{name ? name : ""}</Name>
                    <Bio>{bio ? bio : ""}</Bio>
                </TextWrapper>
            </Content>
        </Container>
    )
}

export default CollaboratorHeader