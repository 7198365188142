import scrollTo from 'gatsby-plugin-smoothscroll';
import React from 'react'
import styled from 'styled-components';
import { Flex } from '../../layout/reusables';

export default function ContentTypesBanner({contentTypes}) {

    return (
        <Container>
            <TypesContainer count={contentTypes.length}>
                {contentTypes.map((type, index) => (
                    <ContentTypeItem
                        key={index}
                        type={type}
                        // selected={type === contentType}
                        clickHandler={() => scrollTo(`#${type.toLowerCase()}-group`)}
                    />
                ))}
            </TypesContainer>
        </Container>
    )
}

const Container = styled(Flex)`
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-bottom: 40px;
`
const TypesContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(${props => props.count}, auto);
    gap: 20px;
    width: 100%;
    @media (max-width: 550px) {
        overflow-x: scroll;
        justify-content: flex-start;
        gap: 8px;
    }
`

const ContentTypeItem = ({ selected, type, clickHandler }) => {
    return (
        <TypeContainer selected={selected} onClick={() => clickHandler(type)}>
            <Type selected={selected}>{type}</Type>
        </TypeContainer>
    )
}

const TypeContainer = styled.button`
    border: 1px solid #c6c6c6;
    padding: 8px 30px;
    border-radius: 2px;
    background: ${props => (props.selected ? "black" : "transparent")};
    outline: none;
    cursor: pointer;
    transition: 0.2s ease-out;
`
const Type = styled.p`
    font-weight: 400;
    color: ${props => (props.selected ? "white" : "black")};
    font-size: 18px;
`