import React from 'react'
import styled from 'styled-components';
import { Flex, TextWrapper } from '../../layout/reusables';
import { BodyCopy } from '../../styles/typography';

export default function EmptyOriginalContent({ name }) {
    return (
        <Flex direction="column" align="center" style={{marginBottom: 30}}>
            <TextWrapper>
                <Flex direction="column" align="center">
                    <Image src={require("../../images/empty-resources.svg")} />
                    <BodyCopy
                        style={{
                            marginBottom: 15,
                            textAlign: "center",
                            color: "#515151",
                        }}
                    >
                        {name.trim()} will add Original Content soon. When they do, you can check back here to find their original Articles, Photos, Videos and Audio.
                    </BodyCopy>
                </Flex>
            </TextWrapper>
        </Flex>
    )
}

const Image = styled.img`
    max-height: 300px;
`